<template>
    <div class="container">
        <div class="page-container">
            <el-form :inline="true" class="fr-s-s">
                <el-form-item>
                    <el-input type="text" placeholder="请输入分析结果名称" v-model="query.resultName" clearable></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" plain @click="refreshData">搜索</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" plain
                        v-if="permission.saveAnalysisResultInfo && permission.selectQuestionnaireQuestionList && permission.selectQuestionnaireQuestionAnswerList && permission.selectConsultationTypeList"
                        @click="onAdd">添加</el-button>
                </el-form-item>
            </el-form>
            <el-table ref="table" :data="tableData" border stripe :row-key="'analysisResultInfoId'"
                v-loading="showLoading" :height="tableHeight">
                <el-table-column prop="analysisResultInfoId" label="编号" align="center" width="60">
                    <template slot-scope="scope"> {{ scope.$index + 1 }}</template>
                </el-table-column>
                <el-table-column prop="resultName" label="分析结果名称" align="left" min-width="100"></el-table-column>
                <el-table-column prop="typeName" label="问诊类型" align="center" width="100"></el-table-column>
                <el-table-column prop="questionTitle" label="问题标题" align="left" min-width="100"></el-table-column>
                <el-table-column prop="answerName" label="选项" align="left" min-width="120"></el-table-column>
                <el-table-column prop="treatmentPrinciple" label="治疗原则" align="left" min-width="240"></el-table-column>
                <el-table-column prop="groupId" label="分组" align="center" width="90px"></el-table-column>
                <el-table-column prop="sortOrder" label="排序" align="center" width="90px"></el-table-column>
                <el-table-column label="操作" width="100px" align="center" fixed="right">
                    <template slot-scope="scope">
                        <el-tooltip class="item" effect="dark" content="编辑" placement="top"
                            v-if="permission.updateAnalysisResultInfo && permission.selectQuestionnaireQuestionList && permission.selectQuestionnaireQuestionAnswerList && permission.selectConsultationTypeList">
                            <el-button class=" success" type="text" icon="el-icon-edit-outline"
                                @click="clickEditInfo(scope.row)"></el-button>
                        </el-tooltip>
                        <el-tooltip class="item" effect="dark" content="删除" placement="top"
                            v-if="permission.removeAnalysisResultInfo">
                            <el-popconfirm title="您确定要删除么？" icon-color="red"
                                @confirm="deleteAdmin(scope.row, scope.$index)">
                                <el-button slot="reference" icon="el-icon-delete" type="text" class="danger">
                                </el-button>
                            </el-popconfirm>
                        </el-tooltip>
                        <el-tooltip class="item" effect="dark" content="选择" placement="top"
                            v-if="permission.selectAnalysisResultList">
                            <el-button icon="el-icon-circle-check" type="text" class="success"
                                @click="selectAnalysisResultHandle(scope.row)"></el-button>
                        </el-tooltip>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination ref="pagination" @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="query.offset" :page-sizes="[10, 30, 50, 100]" :page-size="query.limit"
                layout="total, sizes, prev, pager, next, jumper" :total="pageTotal">
            </el-pagination>
        </div>
        <!-- 查看详情 -->
        <show-content v-model="dialogSeeContent" :title="rowInfo.title" :content="rowInfo.content"></show-content>

        <!-- 添加分析结果 -->
        <el-dialog :title="addOrEditTitle" :visible.sync="dialogAddOrEditVisiable" :modal="false"
            :destroy-on-close="true" fullscreen class="absolute">
            <AddOrEditAnalysisResultInfo :dataInfo="currentModel" @hideShow="dialogAddOrEditVisiable = false"
                @addSuccess="refreshData()" v-if="dialogAddOrEditVisiable" />
        </el-dialog>

    </div>
</template>

<script>
import {
    getList
} from '@/tools/getList.js'
import treatmentManager from "@/request/treatmentManager.js";
import AddOrEditAnalysisResultInfo from '@/components/page/treatment/result/sub/AddOrEditAnalysisResultInfo.vue'
// import AnalysisResultGoodsList from '@/components/page/treatment/result/sub/AnalysisResultGoodsList.vue'

import ShowContent from "@/components/common/subgroup/ShowContent.vue"

export default {
    mixins: [getList],
    components: {
        ShowContent,
        AddOrEditAnalysisResultInfo,
        // AnalysisResultGoodsList
    },
    props: [],
    data() {
        return {
            requst: {
                module: treatmentManager,
                url: 'analysisResulList',
            },
            dialogSeeContent: false,
            rowInfo: {
                title: "",
                content: "",
            },
            query: {
                resultName: '',
            },

            // 添加修改
            addOrEditTitle: '',
            currentModel: null, // 当前操作的对象
            dialogAddOrEditVisiable: false,

        };
    },
    created() {
    },
    methods: {
        // 点击添加
        onAdd() {
            this.currentModel = null
            this.addOrEditTitle = '添加问卷分析结果配置'
            this.dialogAddOrEditVisiable = true
        },

        clickEditInfo(row) {
            this.currentModel = row
            this.addOrEditTitle = '修改问卷分析结果配置'
            this.dialogAddOrEditVisiable = true
        },
        // 点击删除
        async deleteAdmin(row, index) {
            try {
                let { code, message } = await treatmentManager.removeAnalysisResultInfo({ analysisResultInfoId: row.analysisResultInfoId });
                if (code === 200) {
                    this.$message({
                        message: message,
                        type: 'success'
                    });
                    this.delRefresh(index);
                }
            } catch (error) {
                console.log(error)
            }
        },

        selectAnalysisResultHandle(row) {
            this.$emit('selectAnalysisResult', row)
        }
    },
};
</script>

<style scoped></style>
