<template>
    <div class="container">
        <div class="page-container">
            <el-form class="form-wrap" label-position="left" label-width="140px" ref="addOrEditModel"
                :model="addOrEditModel" :rules="addRules">
                <el-form-item label="商品成分名" prop="componentName">
                    <el-input size="medium" v-model="addOrEditModel.componentName" placeholder="请填写商品成分名"></el-input>
                </el-form-item>
                <el-form-item label="商品成分介绍" prop="componentContent">
                    <el-input type="textarea" size="medium" v-model="addOrEditModel.componentContent"
                        placeholder="请填写商品成分介绍"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer" style="text-align: right">
                <el-button @click="clickAddOrEditCancel">取 消</el-button>
                <el-button type="primary" :loading="addOrEditBtnLoading" :disabled="addOrEditBtnLoading"
                    @click="clickAddOrEditConfirm">确定</el-button>
            </div>
        </div>
    </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》'

import treatmentManager from '@/request/treatmentManager.js'
export default {
    // import引入的组件需要注入到对象中才能使用
    components: {

    },
    props: ['dataInfo'],
    data() {
        // 这里存放数据
        return {
            requst: {
                module: treatmentManager,
                addUrl: 'saveComponentManagementInfo',
                updateUrl: 'updateComponentManagementInfo',
            },
            addOrEditBtnLoading: false,
            addOrEditModel: {
                componentManagementId: 0,
                componentName: '',
                componentContent: ''
            },
            addRules: {
                componentName: [
                    { required: true, message: '请输入分类名称', trigger: 'blur' },
                ],
            },
        }
    },
    // 监听属性 类似于data概念
    computed: {},
    // 监控data中的数据变化
    watch: {},
    // 生命周期 - 创建完成（可以访问当前this实例）
    created() {
        if (this.dataInfo) {
            this.addOrEditModel.componentManagementId = this.dataInfo.componentManagementId
            this.addOrEditModel.componentName = this.dataInfo.componentName
            this.addOrEditModel.componentContent = this.dataInfo.componentContent
        }
    },
    // 生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {

    },
    // 方法集合
    methods: {

        clickAddOrEditCancel() {
            this.$emit('hideShow')
        },
        clickAddOrEditConfirm() {
            this.$refs.addOrEditModel.validate(async (valid) => {
                if (valid) {
                    try {
                        this.addOrEditBtnLoading = true
                        var obj;
                        if (this.addOrEditModel.componentManagementId == 0) {
                            obj = {
                                componentName: this.addOrEditModel.componentName,
                                componentContent: this.addOrEditModel.componentContent,
                            }
                        } else {
                            obj = {
                                componentManagementId: this.addOrEditModel.componentManagementId,
                                componentName: this.addOrEditModel.componentName,
                                componentContent: this.addOrEditModel.componentContent,
                            }
                        }
                        let dataValue = JSON.parse(JSON.stringify(this.addOrEditModel))
                        let data = await this.requst.module[this.addOrEditModel.componentManagementId > 0 ? this.requst.updateUrl : this.requst.addUrl](dataValue)
                        if (data.code == 200) {
                            this.$message({ message: data.message, type: 'success', })
                            this.$emit('addSuccess')
                        }
                    } catch (error) {
                        console.log(error)
                    } finally {
                        this.addOrEditBtnLoading = false
                    }
                }
            })
        },
    },
    // 生命周期 - 创建之前
    beforeCreate() { },
    // 生命周期 - 挂载之前
    beforeMount() { },
    // 生命周期 - 更新之前
    beforeUpdate() { },
    // 生命周期 - 更新之后
    updated() { },
    // 生命周期 - 销毁之前
    beforeDestroy() { },
    // 生命周期 - 销毁完成
    destroyed() { },
    //  // 如果页面有keep-alive缓存功能，这个函数会触发
    activated() { },
}
</script>

<style scoped></style>
