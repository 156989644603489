<template>
    <div class="container">
        <div class="page-container">
            <el-form label-width="120px" ref="addOrEditModel" :model="addOrEditModel" :rules="addRules">
                <el-form-item label="分析结果名称" prop="resultName">
                    <el-input size="medium" v-model="addOrEditModel.resultName" placeholder="请输入分析结果名称"></el-input>
                </el-form-item>
                <el-form-item label="问诊类型" prop="consultationTypeId">
                    <el-cascader ref="myCascader" v-model="addOrEditModel.consultationTypeId"
                        :options="searchForm.consultationTypeList" :props="{
                            'value': 'id', 'label': 'typeName'
                        }" @change="handleChange"></el-cascader>
                </el-form-item>
                <el-form-item label="治疗原则" prop="treatmentPrinciple">
                    <el-input type="textarea" size="medium" v-model="addOrEditModel.treatmentPrinciple"
                        placeholder="请输入治疗原则"></el-input>
                </el-form-item>
                <el-form-item label="分组" prop="groupId">
                    <el-select size="medium" v-model="addOrEditModel.groupId" placeholder="请选择" filterable>
                        <el-option v-for="item in searchForm.groupList" :key="item.value" :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="问题：" prop="questionId">
                    <el-button style="margin-bottom:10px" @click="selectQuestionHandle">选择问题</el-button>
                    <el-input size="small" v-model="addOrEditModel.questionTitle" placeholder="请选择问题"
                        disabled></el-input>
                </el-form-item>
                <el-form-item label="选项" prop="answerName" v-if="addOrEditModel.questionId">
                    <el-select size="medium" v-model="addOrEditModel.answerName" multiple placeholder="请选择"
                        style="width: 600px" filterable>
                        <el-option v-for="item in dataList" :key="item.id" :label="item.questionAnswerName"
                            :value="item.questionAnswerName">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="排序" prop="sortOrder">
                    <el-input type="number" size="medium" v-model="addOrEditModel.sortOrder"
                        placeholder="请输入排序"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer" style="text-align: right;">
                <el-button @click="clickAddOrEditCancel">取 消</el-button>
                <el-button type="primary" :loading="addOrEditBtnLoading" @click="clickAddOrEditConfirm">确定</el-button>
            </div>
        </div>
        <el-dialog title="选择问题" :visible.sync="dialogSelectQuestionListVisible" :modal="false" :destroy-on-close="true"
            class="absolute" fullscreen>
            <QuestionnaireQuestionsList @changeSelectQuestion="changeQuestionHandle" :questionnaireType="2"
                v-if="dialogSelectQuestionListVisible" />
        </el-dialog>

    </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》'
import {
    getList
} from '@/tools/getList.js'
import treatmentManager from "@/request/treatmentManager.js";
import systemSetManager from "@/request/systemSetManager.js";
import questionnaireManager from "@/request/questionnaireManager.js";
import QuestionnaireQuestionsList from '@/components/page/questionnaire/question/QuestionList.vue'
export default {
    // import引入的组件需要注入到对象中才能使用
    mixins: [getList],
    components: {
        QuestionnaireQuestionsList
    },
    props: ['dataInfo'],
    data() {
        // 这里存放数据
        return {
            addOrEditBtnLoading: false,
            addOrEditModel: {
                analysisResultInfoId: '',
                resultName: "",
                questionId: "",
                questionTitle: '',
                answerName: [],
                consultationTypeId: [],
                typeName: '',
                groupId: '',
                sortOrder: 9,
                treatmentPrinciple: "",
            },
            addRules: {
                resultName: [{ required: true, message: "请输入分析结果名称", trigger: "blur" },],
                treatmentPrinciple: [{ required: true, message: "请输入治疗原则", trigger: "blur" },],
                questionId: [{ required: true, message: "请选择核心问题", trigger: "blur" },],
                sortOrder: [{ required: true, message: "请输入排序", trigger: "blur" },],
                answerName: [{ required: true, message: "请选择选项", trigger: "blur" },],
                consultationTypeId: [{ required: true, message: "请选择问诊类型", trigger: "blur" },],
                groupId: [{ required: true, message: "请选择分组", trigger: "blur" },],
            },
            dataList: [],

            //选择问题
            dialogSelectQuestionListVisible: false,

            searchForm: {
                consultationTypeList: [],
                groupList: [{
                    value: 1,
                    label: 1,
                }, {
                    value: 2,
                    label: 2,
                }, {
                    value: 3,
                    label: 3,
                }, {
                    value: 4,
                    label: 4,
                }, {
                    value: 5,
                    label: 5,
                }, {
                    value: 6,
                    label: 6,
                }, {
                    value: 7,
                    label: 7,
                }, {
                    value: 8,
                    label: 8,
                }, {
                    value: 9,
                    label: 9,
                }, {
                    value: 10,
                    label: 10,
                },],
            }
        }
    },
    // 监听属性 类似于data概念
    computed: {},
    // 监控data中的数据变化
    watch: {},
    // 生命周期 - 创建完成（可以访问当前this实例）
    created() {
        if (this.dataInfo) {
            this.addOrEditModel.analysisResultInfoId = this.dataInfo.analysisResultInfoId
            this.addOrEditModel.resultName = this.dataInfo.resultName
            this.addOrEditModel.questionId = this.dataInfo.questionId
            this.addOrEditModel.questionTitle = this.dataInfo.questionTitle
            this.addOrEditModel.answerName = this.dataInfo.answerName.split(',')
            if (this.dataInfo.parentId > 0) {
                this.addOrEditModel.consultationTypeId = [this.dataInfo.parentId, this.dataInfo.consultationTypeId]
            } else {
                this.addOrEditModel.consultationTypeId = [this.dataInfo.consultationTypeId]
            }
            this.addOrEditModel.typeName = this.dataInfo.typeName
            this.addOrEditModel.groupId = this.dataInfo.groupId
            this.addOrEditModel.sortOrder = this.dataInfo.sortOrder
            this.addOrEditModel.treatmentPrinciple = this.dataInfo.treatmentPrinciple
            this.getQuestionAnswerList(this.addOrEditModel.questionId)
        }
        this.getConsultationTypeList()
    },
    // 生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {

    },
    // 方法集合
    methods: {
        async getConsultationTypeList() {
            try {
                let data = await systemSetManager.consultationTypeList({
                    isDelete: 0
                });
                if (data.code == 200) {
                    this.searchForm.consultationTypeList = this.toTree(data.data)
                }
            } catch (error) {
                //
            }
        },
        //处理数据
        toTree(data) {
            let result = []
            if (!Array.isArray(data)) {
                return result
            }
            data.forEach((item) => {
                item.children = ''
            })
            let map = {}
            data.forEach((item) => {
                map[item.id] = item
            })
            data.forEach((item) => {
                let parent = map[item.parentId]
                if (parent) {
                    (parent.children || (parent.children = [])).push(item)
                } else {
                    result.push(item)
                }
            })
            return result
        },
        handleChange(value) {
            if (value) {
                const labelArr = this.$refs.myCascader.getCheckedNodes()[0].pathLabels;
                this.addOrEditModel.typeName = labelArr[labelArr.length - 1]
            } else {
                this.addOrEditModel.typeName = ''
            }
        },


        clickAddOrEditCancel() {
            this.$emit('hideShow')
        },
        clickAddOrEditConfirm() {
            this.$refs.addOrEditModel.validate(async (valid) => {
                if (valid) {
                    try {
                        this.addOrEditBtnLoading = true
                        var obj = {
                            resultName: this.addOrEditModel.resultName,
                            questionId: this.addOrEditModel.questionId,
                            answerName: this.addOrEditModel.answerName.toString(),
                            consultationTypeId: this.addOrEditModel.consultationTypeId[this.addOrEditModel.consultationTypeId.length - 1],
                            typeName: this.addOrEditModel.typeName,
                            groupId: this.addOrEditModel.groupId,
                            sortOrder: this.addOrEditModel.sortOrder,
                            treatmentPrinciple: this.addOrEditModel.treatmentPrinciple,
                        }
                        if (this.addOrEditModel.analysisResultInfoId > 0) {
                            obj.analysisResultInfoId = this.addOrEditModel.analysisResultInfoId
                        }

                        let dataValue = JSON.parse(JSON.stringify(obj))
                        let data = await treatmentManager[this.addOrEditModel.analysisResultInfoId > 0 ? 'updateAnalysisResultInfo' : 'saveAnalysisResultInfo'](dataValue)
                        if (data.code == 200) {
                            this.$message({ message: data.message, type: 'success', })
                            this.clickAddOrEditCancel()
                            this.$emit('addSuccess')
                        }
                    } catch (error) {
                        console.log(error)
                    } finally {
                        this.addOrEditBtnLoading = false
                    }
                } else {
                    return false;
                }
            });
        },


        //选择核心问题
        selectQuestionHandle() {
            this.dialogSelectQuestionListVisible = true
        },
        changeQuestionHandle(row) {
            if (row.questionId != this.addOrEditModel.questionId) {
                this.addOrEditModel.questionId = row.id
                this.addOrEditModel.questionTitle = row.questionTitle
                this.dialogSelectQuestionListVisible = false
                this.getQuestionAnswerList(row.id)
                this.addOrEditModel.answerName = [];
            } else {
                this.dialogSelectQuestionListVisible = false
            }
        },
        async getQuestionAnswerList(questionId) {
            this.dataList = []
            try {
                let data = await questionnaireManager.questionnaireQuestionAnswerList({ questionId })
                if (data.code == 200) {
                    this.dataList = data.data
                }
            } catch (error) {
                //
            }
        }




    },
    // 生命周期 - 创建之前
    beforeCreate() { },
    // 生命周期 - 挂载之前
    beforeMount() { },
    // 生命周期 - 更新之前
    beforeUpdate() { },
    // 生命周期 - 更新之后
    updated() { },
    // 生命周期 - 销毁之前
    beforeDestroy() { },
    // 生命周期 - 销毁完成
    destroyed() { },
    //  // 如果页面有keep-alive缓存功能，这个函数会触发
    activated() { },
}
</script>

<style scoped></style>
