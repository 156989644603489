<template>
    <div class="container">
        <div class="page-container">
            <el-form label-width="120px" ref="addOrEditModel" :model="addOrEditModel" :rules="addRules">
                <el-form-item label="项目名称" prop="projectName">
                    <el-input size="medium" v-model="addOrEditModel.projectName" placeholder="请输入项目名称"></el-input>
                </el-form-item>
                <el-form-item label="建议次数" prop="suggestedFrequency">
                    <el-input size="medium" v-model="addOrEditModel.suggestedFrequency" placeholder="请输入建议次数"
                        @input="addOrEditModel.suggestedFrequency = addOrEditModel.suggestedFrequency.replace(/[^0-9]/g, '')"></el-input>
                </el-form-item>
                <el-form-item label="治疗方案描述" prop="treatmentDescription">
                    <el-input type="textarea" size="medium" v-model="addOrEditModel.treatmentDescription"
                        placeholder="请输入治疗方案描述"></el-input>
                </el-form-item>
                <el-form-item label="分析结果：" prop="analysisResultInfoId">
                    <el-button style="margin-bottom:10px" @click="analysisResultHandle">选择分析结果</el-button>
                    <el-input size="small" v-model="addOrEditModel.resultName" placeholder="请选择分析结果"
                        disabled></el-input>
                </el-form-item>
                <el-form-item label="图文教程" prop="graphicTutorial">
                    <Editor v-model="addOrEditModel.graphicTutorial"></Editor>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer" style="text-align: right;">
                <el-button @click="clickAddOrEditCancel">取 消</el-button>
                <el-button type="primary" :loading="addOrEditBtnLoading" @click="clickAddOrEditConfirm">确定</el-button>
            </div>
        </div>
        <el-dialog title="选择分析结果" :visible.sync="dialogAnalysisResultListVisible" :modal="false"
            :destroy-on-close="true" class="absolute" fullscreen>
            <AnalysisResultList @selectAnalysisResult="selectAnalysisResultHandle"
                v-if="dialogAnalysisResultListVisible" />
        </el-dialog>
    </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》'
import treatmentManager from "@/request/treatmentManager.js";
import AnalysisResultList from '@/components/page/treatment/result/AnalysisResultList.vue'
import Editor from '@/components/common/subgroup/Editor.vue' //编辑器
export default {
    props: ['dataInfo'],
    // import引入的组件需要注入到对象中才能使用
    components: {
        AnalysisResultList,
        Editor
    },
    data() {
        // 这里存放数据
        return {
            addOrEditBtnLoading: false,
            addOrEditModel: {
                treatmentProjectId: '',
                analysisResultInfoId: '',
                resultName: '',
                projectName: '',
                suggestedFrequency: '',
                treatmentDescription: '',
                graphicTutorial: '',
            },
            addRules: {
                analysisResultInfoId: [{ required: true, message: "请选择分析结果", trigger: "blur" },],
                projectName: [{ required: true, message: "请输入项目名称", trigger: "blur" },],
                suggestedFrequency: [{ required: true, message: "请输入建议次数", trigger: "blur" },],
                treatmentDescription: [{ required: true, message: "请输入治疗方案描述", trigger: "blur" },],
                graphicTutorial: [{ required: true, message: "请输入图文教程", trigger: "blur" },],
            },

            // 选择
            dialogAnalysisResultListVisible: false,
        }
    },
    // 监听属性 类似于data概念
    computed: {},
    // 监控data中的数据变化
    watch: {},
    // 生命周期 - 创建完成（可以访问当前this实例）
    created() {
        if (this.dataInfo) {
            this.addOrEditModel.treatmentProjectId = this.dataInfo.treatmentProjectId
            this.addOrEditModel.analysisResultInfoId = this.dataInfo.analysisResultInfoId
            this.addOrEditModel.resultName = this.dataInfo.resultName
            this.addOrEditModel.projectName = this.dataInfo.projectName
            this.addOrEditModel.suggestedFrequency = this.dataInfo.suggestedFrequency
            this.addOrEditModel.treatmentDescription = this.dataInfo.treatmentDescription
            this.addOrEditModel.graphicTutorial = this.dataInfo.graphicTutorial
        }
    },
    // 生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {

    },
    // 方法集合
    methods: {
        clickAddOrEditCancel() {
            this.$emit('hideShow')
        },
        analysisResultHandle() {
            this.dialogAnalysisResultListVisible = true
        },
        selectAnalysisResultHandle(row) {
            this.addOrEditModel.analysisResultInfoId = row.analysisResultInfoId
            this.addOrEditModel.resultName = row.resultName
            this.dialogAnalysisResultListVisible = false
        },
        clickAddOrEditConfirm() {
            this.$refs.addOrEditModel.validate(async (valid) => {
                if (valid) {
                    try {
                        this.addOrEditBtnLoading = true
                        var obj = {
                            analysisResultInfoId: this.addOrEditModel.analysisResultInfoId,
                            resultName: this.addOrEditModel.resultName,
                            projectName: this.addOrEditModel.projectName,
                            suggestedFrequency: this.addOrEditModel.suggestedFrequency,
                            treatmentDescription: this.addOrEditModel.treatmentDescription,
                            graphicTutorial: this.addOrEditModel.graphicTutorial,
                        }
                        if (this.addOrEditModel.treatmentProjectId > 0) {
                            obj.treatmentProjectId = this.addOrEditModel.treatmentProjectId
                        }

                        let dataValue = JSON.parse(JSON.stringify(obj))
                        let data = await treatmentManager[this.addOrEditModel.treatmentProjectId > 0 ? 'updateTreatmentProjectInfo' : 'saveTreatmentProjectInfo'](dataValue)
                        if (data.code == 200) {
                            this.$message({ message: data.message, type: 'success', })
                            this.clickAddOrEditCancel()
                            this.$emit('addSuccess')
                        }
                    } catch (error) {
                        console.log(error)
                    } finally {
                        this.addOrEditBtnLoading = false
                    }
                } else {
                    return false;
                }
            });
        },
    },
    // 生命周期 - 创建之前
    beforeCreate() { },
    // 生命周期 - 挂载之前
    beforeMount() { },
    // 生命周期 - 更新之前
    beforeUpdate() { },
    // 生命周期 - 更新之后
    updated() { },
    // 生命周期 - 销毁之前
    beforeDestroy() { },
    // 生命周期 - 销毁完成
    destroyed() { },
    //  // 如果页面有keep-alive缓存功能，这个函数会触发
    activated() { },
}
</script>

<style scoped></style>
