var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "div",
        { staticClass: "page-container" },
        [
          _c(
            "el-form",
            {
              ref: "addOrEditModel",
              attrs: {
                "label-width": "120px",
                model: _vm.addOrEditModel,
                rules: _vm.addRules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "分析结果名称", prop: "resultName" } },
                [
                  _c("el-input", {
                    attrs: {
                      size: "medium",
                      placeholder: "请输入分析结果名称",
                    },
                    model: {
                      value: _vm.addOrEditModel.resultName,
                      callback: function ($$v) {
                        _vm.$set(_vm.addOrEditModel, "resultName", $$v)
                      },
                      expression: "addOrEditModel.resultName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "问诊类型", prop: "consultationTypeId" } },
                [
                  _c("el-cascader", {
                    ref: "myCascader",
                    attrs: {
                      options: _vm.searchForm.consultationTypeList,
                      props: {
                        value: "id",
                        label: "typeName",
                      },
                    },
                    on: { change: _vm.handleChange },
                    model: {
                      value: _vm.addOrEditModel.consultationTypeId,
                      callback: function ($$v) {
                        _vm.$set(_vm.addOrEditModel, "consultationTypeId", $$v)
                      },
                      expression: "addOrEditModel.consultationTypeId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "治疗原则", prop: "treatmentPrinciple" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      size: "medium",
                      placeholder: "请输入治疗原则",
                    },
                    model: {
                      value: _vm.addOrEditModel.treatmentPrinciple,
                      callback: function ($$v) {
                        _vm.$set(_vm.addOrEditModel, "treatmentPrinciple", $$v)
                      },
                      expression: "addOrEditModel.treatmentPrinciple",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "分组", prop: "groupId" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        size: "medium",
                        placeholder: "请选择",
                        filterable: "",
                      },
                      model: {
                        value: _vm.addOrEditModel.groupId,
                        callback: function ($$v) {
                          _vm.$set(_vm.addOrEditModel, "groupId", $$v)
                        },
                        expression: "addOrEditModel.groupId",
                      },
                    },
                    _vm._l(_vm.searchForm.groupList, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "问题：", prop: "questionId" } },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-bottom": "10px" },
                      on: { click: _vm.selectQuestionHandle },
                    },
                    [_vm._v("选择问题")]
                  ),
                  _c("el-input", {
                    attrs: {
                      size: "small",
                      placeholder: "请选择问题",
                      disabled: "",
                    },
                    model: {
                      value: _vm.addOrEditModel.questionTitle,
                      callback: function ($$v) {
                        _vm.$set(_vm.addOrEditModel, "questionTitle", $$v)
                      },
                      expression: "addOrEditModel.questionTitle",
                    },
                  }),
                ],
                1
              ),
              _vm.addOrEditModel.questionId
                ? _c(
                    "el-form-item",
                    { attrs: { label: "选项", prop: "answerName" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "600px" },
                          attrs: {
                            size: "medium",
                            multiple: "",
                            placeholder: "请选择",
                            filterable: "",
                          },
                          model: {
                            value: _vm.addOrEditModel.answerName,
                            callback: function ($$v) {
                              _vm.$set(_vm.addOrEditModel, "answerName", $$v)
                            },
                            expression: "addOrEditModel.answerName",
                          },
                        },
                        _vm._l(_vm.dataList, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: {
                              label: item.questionAnswerName,
                              value: item.questionAnswerName,
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "排序", prop: "sortOrder" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "number",
                      size: "medium",
                      placeholder: "请输入排序",
                    },
                    model: {
                      value: _vm.addOrEditModel.sortOrder,
                      callback: function ($$v) {
                        _vm.$set(_vm.addOrEditModel, "sortOrder", $$v)
                      },
                      expression: "addOrEditModel.sortOrder",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "right" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.clickAddOrEditCancel } }, [
                _vm._v("取 消"),
              ]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.addOrEditBtnLoading },
                  on: { click: _vm.clickAddOrEditConfirm },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "absolute",
          attrs: {
            title: "选择问题",
            visible: _vm.dialogSelectQuestionListVisible,
            modal: false,
            "destroy-on-close": true,
            fullscreen: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogSelectQuestionListVisible = $event
            },
          },
        },
        [
          _vm.dialogSelectQuestionListVisible
            ? _c("QuestionnaireQuestionsList", {
                attrs: { questionnaireType: 2 },
                on: { changeSelectQuestion: _vm.changeQuestionHandle },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }