var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c(
      "div",
      { staticClass: "page-container" },
      [
        _c(
          "el-form",
          {
            ref: "addOrEditModel",
            staticClass: "form-wrap",
            attrs: {
              "label-position": "left",
              "label-width": "140px",
              model: _vm.addOrEditModel,
              rules: _vm.addRules,
            },
          },
          [
            _c(
              "el-form-item",
              { attrs: { label: "商品成分名", prop: "componentName" } },
              [
                _c("el-input", {
                  attrs: { size: "medium", placeholder: "请填写商品成分名" },
                  model: {
                    value: _vm.addOrEditModel.componentName,
                    callback: function ($$v) {
                      _vm.$set(_vm.addOrEditModel, "componentName", $$v)
                    },
                    expression: "addOrEditModel.componentName",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "商品成分介绍", prop: "componentContent" } },
              [
                _c("el-input", {
                  attrs: {
                    type: "textarea",
                    size: "medium",
                    placeholder: "请填写商品成分介绍",
                  },
                  model: {
                    value: _vm.addOrEditModel.componentContent,
                    callback: function ($$v) {
                      _vm.$set(_vm.addOrEditModel, "componentContent", $$v)
                    },
                    expression: "addOrEditModel.componentContent",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "dialog-footer",
            staticStyle: { "text-align": "right" },
            attrs: { slot: "footer" },
            slot: "footer",
          },
          [
            _c("el-button", { on: { click: _vm.clickAddOrEditCancel } }, [
              _vm._v("取 消"),
            ]),
            _c(
              "el-button",
              {
                attrs: {
                  type: "primary",
                  loading: _vm.addOrEditBtnLoading,
                  disabled: _vm.addOrEditBtnLoading,
                },
                on: { click: _vm.clickAddOrEditConfirm },
              },
              [_vm._v("确定")]
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }