<template>
    <div class="container">
        <div class="page-container">
            <el-form :inline="true" class="fr-s-s">
                <el-form-item>
                    <el-input type="text" placeholder="请输入商品成分名" v-model="query.componentName" clearable></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" plain @click="refreshData">搜索</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" plain v-if="permission.saveComponentManagementInfo"
                        @click="showContentHandle(1)">添加</el-button>
                </el-form-item>
            </el-form>
            <el-table ref="table" :data="tableData" border stripe v-loading="showLoading" :height="tableHeight">
                <el-table-column label="编号" align="center" width="60">
                    <template slot-scope="scope"> {{ scope.$index + 1 }}</template>
                </el-table-column>
                <el-table-column prop="componentName" label="商品成分名" align="left" min-width="120">
                </el-table-column>
                <el-table-column prop="componentContent" label="商品成分介绍" align="left" min-width="260">
                </el-table-column>
                <el-table-column label="操作" align="center" width="100" fixed="right">
                    <template slot-scope="scope">
                        <el-tooltip class="item" effect="dark" content="修改" placement="top"
                            v-if="permission.updateComponentManagementInfo">
                            <el-button class="success" type="text" icon="el-icon-edit-outline"
                                @click="showContentHandle(2, scope.row)">
                            </el-button>
                        </el-tooltip>
                        <el-tooltip class="item" effect="dark" content="删除" placement="top"
                            v-if="permission.removeComponentManagementInfo">
                            <el-popconfirm title="您确定要删除么？" icon-color="red"
                                @confirm="clickDelInfo(scope.row, scope.$index)">
                                <el-button slot="reference" icon="el-icon-delete" type="text" class="danger">
                                </el-button>
                            </el-popconfirm>
                        </el-tooltip>
                        <el-tooltip class="item" effect="dark" content="选择" placement="top"
                            v-if="permission.selectcomponentManagementList">
                            <el-button icon="el-icon-circle-check" type="text" class="success"
                                @click="selectCompositionHandle(scope.row)"></el-button>
                        </el-tooltip>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination ref="pagination" @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="query.offset" :page-sizes="[10, 30, 50, 100]" :page-size="query.limit"
                layout="total, sizes, prev, pager, next, jumper" :total="pageTotal"></el-pagination>
        </div>

        <el-dialog :title="addOrEditTitle" :visible.sync="dialogAddOrEditVisible" :modal="false"
            :destroy-on-close="true" fullscreen>
            <AddOrEditCompositionInfo v-if="dialogAddOrEditVisible" @hideShow="dialogAddOrEditVisible = false"
                @addSuccess="refreshData(), dialogAddOrEditVisible = false" :dataInfo="addOrEditModel" />
        </el-dialog>

    </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》'
import { getList } from '@/tools/getList.js'
import treatmentManager from '@/request/treatmentManager.js'
import AddOrEditCompositionInfo from '@/components/page/treatment/composition/sub/AddOrEditCompositionInfo.vue'
export default {

    mixins: [getList],
    // import引入的组件需要注入到对象中才能使用
    components: {
        AddOrEditCompositionInfo
    },

    data() {
        // 这里存放数据
        return {
            requst: {
                module: treatmentManager,
                url: 'componentManagementList',
                addUrl: '',
                updateUrl: '',
            },
            query: {
                componentName: '',
            },
            // 添加修改
            addOrEditTitle: '',
            addOrEditModel: null,
            dialogAddOrEditVisible: false,
        }
    },
    // 监听属性 类似于data概念
    computed: {},
    // 监控data中的数据变化
    watch: {},
    // 生命周期 - 创建完成（可以访问当前this实例）
    created() {

    },
    // 生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {
    },
    // 方法集合
    methods: {
        showContentHandle(type, row = {}) {
            switch (type) {
                case 1:
                    this.addOrEditModel = null
                    this.addOrEditTitle = '添加商品成分'
                    this.dialogAddOrEditVisible = true
                    break;
                case 2:
                    this.addOrEditModel = row
                    this.addOrEditTitle = '修改商品成分'
                    this.dialogAddOrEditVisible = true
                    break;
                default:
                    break;
            }
        },
        async clickDelInfo(row, index) {
            try {
                let { code, message } = await treatmentManager.removeComponentManagementInfo({
                    componentManagementId: row.componentManagementId,
                })
                if (code === 200) {
                    this.$message({ message: message, type: 'success', })
                    this.delRefresh(index)
                }
            } catch (error) {
                console.log(error)
            }
        },
        selectCompositionHandle(row){
            this.$emit('changeSelectComposition',row)
        },
    },
    // 生命周期 - 创建之前
    beforeCreate() { },
    // 生命周期 - 挂载之前
    beforeMount() { },
    // 生命周期 - 更新之前
    beforeUpdate() { },
    // 生命周期 - 更新之后
    updated() { },
    // 生命周期 - 销毁之前
    beforeDestroy() { },
    // 生命周期 - 销毁完成
    destroyed() { },
    //  // 如果页面有keep-alive缓存功能，这个函数会触发
    activated() { },
}
</script>

<style scoped></style>
