<template>
    <div class="container">
        <div class="page-container">
            <el-form :inline="true" class="fr-s-s">
                <el-form-item>
                    <el-button type="primary" plain
                        v-if="permission.saveTreatmentProjectGoodsRelationInfo && permission.selectGoods"
                        @click="onAdd">添加</el-button>
                </el-form-item>
            </el-form>
            <el-table ref="table" :data="tableData" border stripe :row-key="'treatmentProjectGoodsRelationId'"
                v-loading="showLoading" :height="tableHeight">
                <el-table-column prop="treatmentProjectGoodsRelationId" label="编号" align="center" width="60">
                    <template slot-scope="scope"> {{ scope.$index + 1 }}</template>
                </el-table-column>
                <el-table-column label="主图" width="70" align="center">
                    <template slot-scope="scope">
                        <el-image style="max-width: 40px; max-height: 40px" class="table-td-thumb"
                            :src="scope.row.goodsImg" :preview-src-list="[scope.row.goodsImg]" fit="cover">
                            <div slot="error" class="image-slot">
                                <i class="el-icon-picture-outline"></i>
                            </div>
                        </el-image>
                    </template>
                </el-table-column>
                <el-table-column prop="goodsName" label="商品名称" align="left" min-width="120">
                </el-table-column>
                <el-table-column prop="quantity" label="商品数量" align="center" width="100">
                </el-table-column>
                <el-table-column prop="price" label="价格" align="center" width="100">
                </el-table-column>
                <el-table-column prop="goodsType" label="商品类型" align="center" width="100">
                    <template slot-scope="scope"> {{ scope.row.goodsType == 3 ? '院线项目' : '家居项目' }}</template>
                </el-table-column>
                <el-table-column label="操作" width="100px" align="center" fixed="right">
                    <template slot-scope="scope">
                        <el-tooltip class="item" effect="dark" content="编辑" placement="top"
                            v-if="permission.updateTreatmentProjectGoodsRelationInfo && permission.selectGoods">
                            <el-button class="success" type="text" icon="el-icon-edit-outline"
                                @click="clickEditInfo(scope.row)"></el-button>
                        </el-tooltip>
                        <el-tooltip class="item" effect="dark" content="删除" placement="top"
                            v-if="permission.removeTreatmentProjectGoodsRelationInfo">
                            <el-popconfirm title="您确定要删除么？" icon-color="red"
                                @confirm="deleteAdmin(scope.row, scope.$index)">
                                <el-button slot="reference" icon="el-icon-delete" type="text" class="danger">
                                </el-button>
                            </el-popconfirm>
                        </el-tooltip>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination ref="pagination" @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="query.offset" :page-sizes="[10, 30, 50, 100]" :page-size="query.limit"
                layout="total, sizes, prev, pager, next, jumper" :total="pageTotal"></el-pagination>
        </div>

        <!-- 添加处方单 -->
        <el-dialog :title="addOrEditTitle" :visible.sync="addOrEditDialog" :modal="false" :destroy-on-close="true"
            fullscreen @close="resetAddOrEditData">
            <el-form label-width="120px" ref="addOrEditModel" :model="addOrEditModel" :rules="addRules">
                <el-form-item label="商品类型" prop="goodsType">
                    <el-select v-model="addOrEditModel.goodsType" placeholder="请选择商品类型">
                        <el-option :value="3" label="院线"></el-option>
                        <el-option :value="4" label="家居"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="关联商品" prop="goodsId">
                    <el-input size="medium" style="width: 300px" v-model="addOrEditModel.goodsName" placeholder="请选择商品"
                        disabled>
                    </el-input>
                    <el-button type="primary" size="medium" style="margin-left: 15px"
                        @click="onchoiceGoods">选择</el-button>
                </el-form-item>
                <el-form-item label="数量" prop="quantity">
                    <el-input type="Number" size="medium" v-model="addOrEditModel.quantity" placeholder="请输入数量"
                        @input="addOrEditModel.quantity = addOrEditModel.quantity.replace(/[^0-9]/g, '')"></el-input>
                </el-form-item>
                <!-- <el-form-item label="价格" prop="price">
                    <el-input type="text" size="medium" v-model="addOrEditModel.price" placeholder="请输入价格"
                        @input="addOrEditModel.price = addOrEditModel.price.replace(/[^0-9.]/g, '')"></el-input>
                </el-form-item> -->

            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="resetAddOrEditData">取 消</el-button>
                <el-button type="primary" :loading="addOrEditBtnLoading" @click="clickAddOrEditConfirm">确定</el-button>
            </div>
        </el-dialog>
        <!-- 选择奖品 -->
        <el-dialog title="选择商品" :visible.sync="dialogGoodsFormVisible" :modal="false" :destroy-on-close="true"
            fullscreen class="absolute">
            <GoodsList @changeSelectGoods="handleChangeSelectGoods" :goodsType="addOrEditModel.goodsType"
                v-if="dialogGoodsFormVisible">
            </GoodsList>
        </el-dialog>
    </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》'
import {
    getList
} from '@/tools/getList.js'
import treatmentManager from "@/request/treatmentManager.js";
import GoodsList from '@/components/page/goods/GoodsList.vue'
export default {
    mixins: [getList],
    props: ['treatmentProjectId'],
    // import引入的组件需要注入到对象中才能使用
    components: {
        GoodsList,
    },
    data() {
        // 这里存放数据
        return {
            requst: {
                module: treatmentManager,
                url: 'treatmentProjectGoodsRelationList',
                addUrl: 'saveTreatmentProjectGoodsRelationInfo',
                updateUrl: 'updateTreatmentProjectGoodsRelationInfo',
            },
            query: {
                treatmentProjectId: this.treatmentProjectId
            },

            addOrEditDialog: false,
            addOrEditTitle: '',
            addOrEditBtnLoading: false,
            addOrEditModel: {
                treatmentProjectGoodsRelationId: 0,
                treatmentProjectId: this.treatmentProjectId,
                goodsId: '',
                goodsImg: '',
                goodsName: '',
                quantity: '',
                goodsType: 3,
                price: '',
            },
            addRules: {
                goodsId: [
                    { required: true, message: '请选择商品', trigger: 'blur' },
                ],
                quantity: [
                    { required: true, message: '请输入数量', trigger: 'blur' },
                ],
                price: [
                    { required: true, message: '请填写商品价格', trigger: 'change' },
                    {
                        pattern: /^(0|[1-9]\d*)(\.\d{1,2})?$/,
                        message: '商品价格必须为大于等于的0(并且只能保留两位小数)',
                        trigger: 'change',
                    },
                ],
                goodsType: [
                    { required: true, message: '请选择商品类型', trigger: 'blur' },
                ],
            },
            // 选择商品
            dialogGoodsFormVisible: false,

            //产品成分
            currnetGoodsID: 0,
            dialogGoodsCompositionListVisible: false,


        }
    },
    // 监听属性 类似于data概念
    computed: {},
    // 监控data中的数据变化
    watch: {},
    // 生命周期 - 创建完成（可以访问当前this实例）
    created() {

    },
    // 生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {

    },
    // 方法集合
    methods: {
        onAdd() {
            this.addOrEditTitle = '添加关联商品'
            this.addOrEditDialog = true
        },
        clickEditInfo(row) {
            this.addOrEditTitle = '修改关联商品'
            this.addOrEditModel = {
                treatmentProjectGoodsRelationId: row.treatmentProjectGoodsRelationId,
                treatmentProjectId: row.treatmentProjectId,
                goodsId: row.goodsId,
                goodsImg: row.goodsImg,
                goodsName: row.goodsName,
                quantity: row.quantity,
                goodsType: row.goodsType,
                price: row.price,
            }
            this.addOrEditDialog = true
        },
        resetAddOrEditData() {
            this.addOrEditModel = {
                treatmentProjectGoodsRelationId: 0,
                treatmentProjectId: this.treatmentProjectId,
                goodsId: '',
                goodsImg: '',
                goodsName: '',
                quantity: '',
                goodsType: 3,
                price: '',
            }
            this.addOrEditDialog = false
        },
        onchoiceGoods() {
            this.dialogGoodsFormVisible = true
        },
        handleChangeSelectGoods(goods) {
            this.addOrEditModel.goodsId = goods.goodsId;
            this.addOrEditModel.goodsName = goods.goodsName;
            this.addOrEditModel.goodsImg = goods.goodsImg;
            this.addOrEditModel.price = goods.goodsPrice;
            this.dialogGoodsFormVisible = false;
        },

        clickAddOrEditConfirm() {
            this.$refs.addOrEditModel.validate(async (valid) => {
                if (valid) {
                    try {
                        this.addOrEditBtnLoading = true
                        let dataValue = JSON.parse(JSON.stringify(this.addOrEditModel))
                        let data = await this.requst.module[this.addOrEditModel.treatmentProjectGoodsRelationId > 0 ? this.requst.updateUrl : this.requst.addUrl](dataValue)
                        if (data.code == 200) {
                            this.$message({ message: data.message, type: 'success', })
                            this.refresCurrentPagehData()
                            this.resetAddOrEditData()
                        }
                    } catch (error) {
                        console.log(error)
                    } finally {
                        this.addOrEditBtnLoading = false
                    }
                } else {
                    return false
                }
            })
        },

        // 点击删除
        async deleteAdmin(row, index) {
            try {
                let { code, message } = await treatmentManager.removeTreatmentProjectGoodsRelationInfo({ treatmentProjectGoodsRelationId: row.treatmentProjectGoodsRelationId });
                if (code === 200) {
                    this.$message({
                        message: message,
                        type: 'success'
                    });
                    this.delRefresh(index);
                }
            } catch (error) {
                console.log(error)
            }
        },

        rowContentHandle(row, type) {
            switch (type) {
                case 1:
                    this.currnetGoodsID = row.goodsId
                    this.dialogGoodsCompositionListVisible = true
                    break;

                default:
                    break;
            }
        },

    },
    // 生命周期 - 创建之前
    beforeCreate() { },
    // 生命周期 - 挂载之前
    beforeMount() { },
    // 生命周期 - 更新之前
    beforeUpdate() { },
    // 生命周期 - 更新之后
    updated() { },
    // 生命周期 - 销毁之前
    beforeDestroy() { },
    // 生命周期 - 销毁完成
    destroyed() { },
    //  // 如果页面有keep-alive缓存功能，这个函数会触发
    activated() { },
}
</script>

<style scoped></style>
