var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "div",
        { staticClass: "page-container" },
        [
          _c(
            "el-form",
            {
              ref: "addOrEditModel",
              attrs: {
                "label-width": "120px",
                model: _vm.addOrEditModel,
                rules: _vm.addRules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "项目名称", prop: "projectName" } },
                [
                  _c("el-input", {
                    attrs: { size: "medium", placeholder: "请输入项目名称" },
                    model: {
                      value: _vm.addOrEditModel.projectName,
                      callback: function ($$v) {
                        _vm.$set(_vm.addOrEditModel, "projectName", $$v)
                      },
                      expression: "addOrEditModel.projectName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "建议次数", prop: "suggestedFrequency" } },
                [
                  _c("el-input", {
                    attrs: { size: "medium", placeholder: "请输入建议次数" },
                    on: {
                      input: function ($event) {
                        _vm.addOrEditModel.suggestedFrequency =
                          _vm.addOrEditModel.suggestedFrequency.replace(
                            /[^0-9]/g,
                            ""
                          )
                      },
                    },
                    model: {
                      value: _vm.addOrEditModel.suggestedFrequency,
                      callback: function ($$v) {
                        _vm.$set(_vm.addOrEditModel, "suggestedFrequency", $$v)
                      },
                      expression: "addOrEditModel.suggestedFrequency",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "治疗方案描述",
                    prop: "treatmentDescription",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      size: "medium",
                      placeholder: "请输入治疗方案描述",
                    },
                    model: {
                      value: _vm.addOrEditModel.treatmentDescription,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.addOrEditModel,
                          "treatmentDescription",
                          $$v
                        )
                      },
                      expression: "addOrEditModel.treatmentDescription",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: { label: "分析结果：", prop: "analysisResultInfoId" },
                },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-bottom": "10px" },
                      on: { click: _vm.analysisResultHandle },
                    },
                    [_vm._v("选择分析结果")]
                  ),
                  _c("el-input", {
                    attrs: {
                      size: "small",
                      placeholder: "请选择分析结果",
                      disabled: "",
                    },
                    model: {
                      value: _vm.addOrEditModel.resultName,
                      callback: function ($$v) {
                        _vm.$set(_vm.addOrEditModel, "resultName", $$v)
                      },
                      expression: "addOrEditModel.resultName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "图文教程", prop: "graphicTutorial" } },
                [
                  _c("Editor", {
                    model: {
                      value: _vm.addOrEditModel.graphicTutorial,
                      callback: function ($$v) {
                        _vm.$set(_vm.addOrEditModel, "graphicTutorial", $$v)
                      },
                      expression: "addOrEditModel.graphicTutorial",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "right" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.clickAddOrEditCancel } }, [
                _vm._v("取 消"),
              ]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.addOrEditBtnLoading },
                  on: { click: _vm.clickAddOrEditConfirm },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "absolute",
          attrs: {
            title: "选择分析结果",
            visible: _vm.dialogAnalysisResultListVisible,
            modal: false,
            "destroy-on-close": true,
            fullscreen: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogAnalysisResultListVisible = $event
            },
          },
        },
        [
          _vm.dialogAnalysisResultListVisible
            ? _c("AnalysisResultList", {
                on: { selectAnalysisResult: _vm.selectAnalysisResultHandle },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }