var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "div",
        { staticClass: "page-container" },
        [
          _c(
            "el-form",
            { staticClass: "fr-s-s", attrs: { inline: true } },
            [
              _c(
                "el-form-item",
                [
                  _vm.permission.saveTreatmentProjectGoodsRelationInfo &&
                  _vm.permission.selectGoods
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary", plain: "" },
                          on: { click: _vm.onAdd },
                        },
                        [_vm._v("添加")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.showLoading,
                  expression: "showLoading",
                },
              ],
              ref: "table",
              attrs: {
                data: _vm.tableData,
                border: "",
                stripe: "",
                "row-key": "treatmentProjectGoodsRelationId",
                height: _vm.tableHeight,
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  prop: "treatmentProjectGoodsRelationId",
                  label: "编号",
                  align: "center",
                  width: "60",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_vm._v(" " + _vm._s(scope.$index + 1))]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "主图", width: "70", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-image",
                          {
                            staticClass: "table-td-thumb",
                            staticStyle: {
                              "max-width": "40px",
                              "max-height": "40px",
                            },
                            attrs: {
                              src: scope.row.goodsImg,
                              "preview-src-list": [scope.row.goodsImg],
                              fit: "cover",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "image-slot",
                                attrs: { slot: "error" },
                                slot: "error",
                              },
                              [
                                _c("i", {
                                  staticClass: "el-icon-picture-outline",
                                }),
                              ]
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "goodsName",
                  label: "商品名称",
                  align: "left",
                  "min-width": "120",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "quantity",
                  label: "商品数量",
                  align: "center",
                  width: "100",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "price",
                  label: "价格",
                  align: "center",
                  width: "100",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "goodsType",
                  label: "商品类型",
                  align: "center",
                  width: "100",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              scope.row.goodsType == 3 ? "院线项目" : "家居项目"
                            )
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  width: "100px",
                  align: "center",
                  fixed: "right",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm.permission
                          .updateTreatmentProjectGoodsRelationInfo &&
                        _vm.permission.selectGoods
                          ? _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: "编辑",
                                  placement: "top",
                                },
                              },
                              [
                                _c("el-button", {
                                  staticClass: "success",
                                  attrs: {
                                    type: "text",
                                    icon: "el-icon-edit-outline",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.clickEditInfo(scope.row)
                                    },
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.permission.removeTreatmentProjectGoodsRelationInfo
                          ? _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: "删除",
                                  placement: "top",
                                },
                              },
                              [
                                _c(
                                  "el-popconfirm",
                                  {
                                    attrs: {
                                      title: "您确定要删除么？",
                                      "icon-color": "red",
                                    },
                                    on: {
                                      confirm: function ($event) {
                                        return _vm.deleteAdmin(
                                          scope.row,
                                          scope.$index
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("el-button", {
                                      staticClass: "danger",
                                      attrs: {
                                        slot: "reference",
                                        icon: "el-icon-delete",
                                        type: "text",
                                      },
                                      slot: "reference",
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("el-pagination", {
            ref: "pagination",
            attrs: {
              "current-page": _vm.query.offset,
              "page-sizes": [10, 30, 50, 100],
              "page-size": _vm.query.limit,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.pageTotal,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.addOrEditTitle,
            visible: _vm.addOrEditDialog,
            modal: false,
            "destroy-on-close": true,
            fullscreen: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.addOrEditDialog = $event
            },
            close: _vm.resetAddOrEditData,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "addOrEditModel",
              attrs: {
                "label-width": "120px",
                model: _vm.addOrEditModel,
                rules: _vm.addRules,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "商品类型", prop: "goodsType" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择商品类型" },
                      model: {
                        value: _vm.addOrEditModel.goodsType,
                        callback: function ($$v) {
                          _vm.$set(_vm.addOrEditModel, "goodsType", $$v)
                        },
                        expression: "addOrEditModel.goodsType",
                      },
                    },
                    [
                      _c("el-option", { attrs: { value: 3, label: "院线" } }),
                      _c("el-option", { attrs: { value: 4, label: "家居" } }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "关联商品", prop: "goodsId" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "300px" },
                    attrs: {
                      size: "medium",
                      placeholder: "请选择商品",
                      disabled: "",
                    },
                    model: {
                      value: _vm.addOrEditModel.goodsName,
                      callback: function ($$v) {
                        _vm.$set(_vm.addOrEditModel, "goodsName", $$v)
                      },
                      expression: "addOrEditModel.goodsName",
                    },
                  }),
                  _c(
                    "el-button",
                    {
                      staticStyle: { "margin-left": "15px" },
                      attrs: { type: "primary", size: "medium" },
                      on: { click: _vm.onchoiceGoods },
                    },
                    [_vm._v("选择")]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "数量", prop: "quantity" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "Number",
                      size: "medium",
                      placeholder: "请输入数量",
                    },
                    on: {
                      input: function ($event) {
                        _vm.addOrEditModel.quantity =
                          _vm.addOrEditModel.quantity.replace(/[^0-9]/g, "")
                      },
                    },
                    model: {
                      value: _vm.addOrEditModel.quantity,
                      callback: function ($$v) {
                        _vm.$set(_vm.addOrEditModel, "quantity", $$v)
                      },
                      expression: "addOrEditModel.quantity",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.resetAddOrEditData } }, [
                _vm._v("取 消"),
              ]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.addOrEditBtnLoading },
                  on: { click: _vm.clickAddOrEditConfirm },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "absolute",
          attrs: {
            title: "选择商品",
            visible: _vm.dialogGoodsFormVisible,
            modal: false,
            "destroy-on-close": true,
            fullscreen: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogGoodsFormVisible = $event
            },
          },
        },
        [
          _vm.dialogGoodsFormVisible
            ? _c("GoodsList", {
                attrs: { goodsType: _vm.addOrEditModel.goodsType },
                on: { changeSelectGoods: _vm.handleChangeSelectGoods },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }