<template>
    <div class="container">
        <div class="page-container">
            <el-form :inline="true" class="fr-s-s">
                <el-form-item>
                    <el-input type="text" placeholder="请输入项目名称" v-model="query.projectName"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-date-picker value-format="timestamp" v-model="query.startTime" type="datetime"
                        placeholder="请选择开始时间" :picker-options="searchPickerOptionsStart"></el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-date-picker value-format="timestamp" v-model="query.endTime" type="datetime" placeholder="结束时间"
                        :picker-options="searchPickerOptionsEnd"></el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" plain @click="refreshData">搜索</el-button>
                </el-form-item>
                <el-form-item v-if="permission.saveTreatmentProjectInfo && permission.selectAnalysisResultList">
                    <el-button type="primary" plain @click="onAdd">添加</el-button>
                </el-form-item>
            </el-form>
            <el-table ref="table" :data="tableData" border stripe :row-key="'treatmentProjectId'"
                v-loading="showLoading" :height="tableHeight">
                <el-table-column prop="treatmentProjectId" label="编号" align="center" width="60">
                    <template slot-scope="scope"> {{ scope.$index + 1 }}</template>
                </el-table-column>
                <el-table-column prop="projectName" label="项目名称" align="left" min-width="120"></el-table-column>
                <el-table-column prop="resultName" label="分析结果名称" align="left" min-width="120"></el-table-column>
                <el-table-column prop="treatmentDescription" label="治疗方案描述" align="left"
                    min-width="180"></el-table-column>
                <el-table-column prop="" label="关联商品" align="center" width="100">
                    <template slot-scope="scope">
                        <template v-if="permission.lookTreatmentProjectGoodsRelationList">
                            <el-link @click="treatmentProjectGoodsRelationHandle(scope.row)" type="primary">查看</el-link>
                        </template>
                    </template>
                </el-table-column>
                <el-table-column prop="suggestedFrequency" label="建议次数" align="center" width="100"></el-table-column>
                <el-table-column prop="" label="图文教程" width="120px" align="center">
                    <template slot-scope="scope">
                        <a v-if="scope.row.graphicTutorial" @click="linUrlDetail(scope.row)">
                            <el-link type="primary">查看详情</el-link>
                        </a>
                    </template>
                </el-table-column>
                <el-table-column prop="createdTime" label="创建时间" align="center" width="140">
                    <template slot-scope="scope"> {{ scope.row.createdTime | formatDate }}</template>
                </el-table-column>
                <el-table-column label="操作" width="100px" align="center" fixed="right">
                    <template slot-scope="scope">
                        <el-tooltip class="item" effect="dark" content="编辑" placement="top"
                            v-if="permission.updateTreatmentProjectInfo && permission.selectAnalysisResultList">
                            <el-button class="success" type="text" icon="el-icon-edit-outline"
                                @click="clickEditInfo(scope.row)"></el-button>
                        </el-tooltip>
                        <el-tooltip class="item" effect="dark" content="删除" placement="top"
                            v-if="permission.removeTreatmentProjectInfo">
                            <el-popconfirm title="您确定要删除么？" icon-color="red"
                                @confirm="deleteAdmin(scope.row, scope.$index)">
                                <el-button slot="reference" icon="el-icon-delete" type="text" class="danger">
                                </el-button>
                            </el-popconfirm>
                        </el-tooltip>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination ref="pagination" @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="query.offset" :page-sizes="[10, 30, 50, 100]" :page-size="query.limit"
                layout="total, sizes, prev, pager, next, jumper" :total="pageTotal"></el-pagination>
        </div>

        <el-dialog :title="addOrEditTitle" :visible.sync="dialogAddOrEditTreatmentProjectVisible" :modal="false"
            :destroy-on-close="true" fullscreen>
            <AddOrEditTreatmentProjectInfo :dataInfo="currentModel"
                @hideShow="dialogAddOrEditTreatmentProjectVisible = false" @addSuccess="refreshData()"
                v-if="dialogAddOrEditTreatmentProjectVisible" />
        </el-dialog>
        <el-dialog title="治疗方案关联商品" :visible.sync="dialogTreatmentProjectGoodsRelationListVisible" :modal="false"
            :destroy-on-close="true" fullscreen>
            <TreatmentProjectGoodsRelationList :treatmentProjectId="currentModel.treatmentProjectId"
                v-if="dialogTreatmentProjectGoodsRelationListVisible" />
        </el-dialog>
        <show-content v-model="dialogSeeContent" :title="rowInfo.title" :content="rowInfo.content"></show-content>
    </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》'
import {
    getList
} from '@/tools/getList.js'
import treatmentManager from "@/request/treatmentManager.js";
import AddOrEditTreatmentProjectInfo from '@/components/page/treatment/project/sub/AddOrEditTreatmentProjectInfo.vue'
import TreatmentProjectGoodsRelationList from '@/components/page/treatment/project/sub/TreatmentProjectGoodsRelationList.vue'
import ShowContent from '@/components/common/subgroup/ShowContent.vue'

export default {
    mixins: [getList],
    // import引入的组件需要注入到对象中才能使用
    components: {
        AddOrEditTreatmentProjectInfo,
        TreatmentProjectGoodsRelationList,
        ShowContent
    },
    data() {
        // 这里存放数据
        return {
            searchPickerOptionsStart: {
                disabledDate: (time) => {
                    if (this.query.endTime) {
                        return time.getTime() > this.query.endTime
                    }
                }
            },
            searchPickerOptionsEnd: {
                disabledDate: (time) => {
                    if (this.query.startTime) {
                        return time.getTime() < this.query.startTime
                    }
                },
            },
            requst: {
                module: treatmentManager,
                url: 'treatmentProjectInfoList',
            },
            query: {
                projectName: '',
                startTime: '',
                endTime: '',
            },
            dialogSeeContent: false,
            rowInfo: {},
            // 添加/修改治疗方案
            addOrEditTitle: '',
            currentModel: null,
            dialogAddOrEditTreatmentProjectVisible: false,
            // 治疗方案关联商品
            dialogTreatmentProjectGoodsRelationListVisible: false,

        }
    },
    // 监听属性 类似于data概念
    computed: {},
    // 监控data中的数据变化
    watch: {},
    // 生命周期 - 创建完成（可以访问当前this实例）
    created() {

    },
    // 生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {

    },
    // 方法集合
    methods: {
        // 点击添加
        onAdd() {
            this.currentModel = null
            this.addOrEditTitle = '添加治疗方案'
            this.dialogAddOrEditTreatmentProjectVisible = true
        },

        clickEditInfo(row) {
            this.currentModel = row
            this.addOrEditTitle = '修改治疗方案'
            this.dialogAddOrEditTreatmentProjectVisible = true
        },

        // 点击删除
        async deleteAdmin(row, index) {
            try {
                let { code, message } = await treatmentManager.removeTreatmentProjectInfo({ treatmentProjectId: row.treatmentProjectId });
                if (code === 200) {
                    this.$message({
                        message: message,
                        type: 'success'
                    });
                    this.delRefresh(index);
                }
            } catch (error) {
                console.log(error)
            }
        },
        treatmentProjectGoodsRelationHandle(row) {
            this.currentModel = row
            this.dialogTreatmentProjectGoodsRelationListVisible = true
        },
        //详情
        linUrlDetail(row) {
            this.rowInfo.title = '图文教程'
            this.rowInfo.content = row.graphicTutorial
            this.dialogSeeContent = true
        },
    },
    // 生命周期 - 创建之前
    beforeCreate() { },
    // 生命周期 - 挂载之前
    beforeMount() { },
    // 生命周期 - 更新之前
    beforeUpdate() { },
    // 生命周期 - 更新之后
    updated() { },
    // 生命周期 - 销毁之前
    beforeDestroy() { },
    // 生命周期 - 销毁完成
    destroyed() { },
    //  // 如果页面有keep-alive缓存功能，这个函数会触发
    activated() { },
}
</script>

<style scoped></style>
