var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "div",
        { staticClass: "page-container" },
        [
          _c(
            "el-form",
            { staticClass: "fr-s-s", attrs: { inline: true } },
            [
              _c(
                "el-form-item",
                [
                  _c("el-input", {
                    attrs: {
                      type: "text",
                      placeholder: "请输入商品成分名",
                      clearable: "",
                    },
                    model: {
                      value: _vm.query.componentName,
                      callback: function ($$v) {
                        _vm.$set(_vm.query, "componentName", $$v)
                      },
                      expression: "query.componentName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", plain: "" },
                      on: { click: _vm.refreshData },
                    },
                    [_vm._v("搜索")]
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _vm.permission.saveComponentManagementInfo
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary", plain: "" },
                          on: {
                            click: function ($event) {
                              return _vm.showContentHandle(1)
                            },
                          },
                        },
                        [_vm._v("添加")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.showLoading,
                  expression: "showLoading",
                },
              ],
              ref: "table",
              attrs: {
                data: _vm.tableData,
                border: "",
                stripe: "",
                height: _vm.tableHeight,
              },
            },
            [
              _c("el-table-column", {
                attrs: { label: "编号", align: "center", width: "60" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_vm._v(" " + _vm._s(scope.$index + 1))]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "componentName",
                  label: "商品成分名",
                  align: "left",
                  "min-width": "120",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "componentContent",
                  label: "商品成分介绍",
                  align: "left",
                  "min-width": "260",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  label: "操作",
                  align: "center",
                  width: "100",
                  fixed: "right",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm.permission.updateComponentManagementInfo
                          ? _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: "修改",
                                  placement: "top",
                                },
                              },
                              [
                                _c("el-button", {
                                  staticClass: "success",
                                  attrs: {
                                    type: "text",
                                    icon: "el-icon-edit-outline",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.showContentHandle(2, scope.row)
                                    },
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.permission.removeComponentManagementInfo
                          ? _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: "删除",
                                  placement: "top",
                                },
                              },
                              [
                                _c(
                                  "el-popconfirm",
                                  {
                                    attrs: {
                                      title: "您确定要删除么？",
                                      "icon-color": "red",
                                    },
                                    on: {
                                      confirm: function ($event) {
                                        return _vm.clickDelInfo(
                                          scope.row,
                                          scope.$index
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("el-button", {
                                      staticClass: "danger",
                                      attrs: {
                                        slot: "reference",
                                        icon: "el-icon-delete",
                                        type: "text",
                                      },
                                      slot: "reference",
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.permission.selectcomponentManagementList
                          ? _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  content: "选择",
                                  placement: "top",
                                },
                              },
                              [
                                _c("el-button", {
                                  staticClass: "success",
                                  attrs: {
                                    icon: "el-icon-circle-check",
                                    type: "text",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.selectCompositionHandle(
                                        scope.row
                                      )
                                    },
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("el-pagination", {
            ref: "pagination",
            attrs: {
              "current-page": _vm.query.offset,
              "page-sizes": [10, 30, 50, 100],
              "page-size": _vm.query.limit,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.pageTotal,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.addOrEditTitle,
            visible: _vm.dialogAddOrEditVisible,
            modal: false,
            "destroy-on-close": true,
            fullscreen: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogAddOrEditVisible = $event
            },
          },
        },
        [
          _vm.dialogAddOrEditVisible
            ? _c("AddOrEditCompositionInfo", {
                attrs: { dataInfo: _vm.addOrEditModel },
                on: {
                  hideShow: function ($event) {
                    _vm.dialogAddOrEditVisible = false
                  },
                  addSuccess: function ($event) {
                    _vm.refreshData(), (_vm.dialogAddOrEditVisible = false)
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }