import { render, staticRenderFns } from "./TreatmentProjectGoodsRelationList.vue?vue&type=template&id=589050c3&scoped=true"
import script from "./TreatmentProjectGoodsRelationList.vue?vue&type=script&lang=js"
export * from "./TreatmentProjectGoodsRelationList.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "589050c3",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/ap/jenkins/workspace/prod-ykd360-admin-front(医可达旧管理端)/node_modules/_vue-hot-reload-api@2.3.4@vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('589050c3')) {
      api.createRecord('589050c3', component.options)
    } else {
      api.reload('589050c3', component.options)
    }
    module.hot.accept("./TreatmentProjectGoodsRelationList.vue?vue&type=template&id=589050c3&scoped=true", function () {
      api.rerender('589050c3', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/page/treatment/project/sub/TreatmentProjectGoodsRelationList.vue"
export default component.exports